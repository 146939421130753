.tabs {
  height: 540px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 20px 20px 0px 0px;
}

.enabled {
  background-color: #fffbf4;
  color: #062a20 !important;
  text-decoration: black;
}

.tab-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 67%;
  height: 46px;
  list-style-type: none;
  background-color: #062a20;
  cursor: pointer;
  border-radius: 20px 20px 0px 0px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.tab {
  /* margin-right: 30px; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  justify-content: center;
  line-height: 91.5%;
  color: #eff3e5;
  width: 25%;
  height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.last-tab {
  border-top-right-radius: 20px;
}

.first-tab {
  border-top-left-radius: 20px;
}

.tab-panel {
  background-color: #fffbf4;
  height: 603px;
  border-top-right-radius: 20px;
}
