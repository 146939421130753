.rc-anchor-dark {
  background: #062a20 !important;
  color: #062a20 !important;
}

[custom-captcha='on'] {
  background: #062a20 !important;
  color: #062a20 !important;
}

.rc-anchor-light {
  background: #062a20 !important;
  color: #062a20 !important;
}
