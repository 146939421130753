.close-button {
  background: red !important;
  margin-left: 10px;
}

[custom-input='on'] {
  border-radius: 0px 30px 30px 0px;
  height: 40px;
  background-color: #dbcfb8;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #062a20;
  padding-left: 8px;
  cursor: pointer;
  width: 12vw;
  border: solid 1.5px #062a20;
  box-sizing: border-box;
}

[custom-button='on'] {
}
