.States {
  text-align: center;
}

path {
  pointer-events: all;
}

[data-interactive='on'] {
  cursor: pointer;
}

[data-interactive='on']:hover {
  opacity: 0.5;
}

/* path:hover {
  
  opacity: 0.5;
} */
/* 
.CA {
  cursor: pointer;
}

.CO {
  cursor: pointer;
}

.NV {
  cursor: pointer;
}

.OR {
  cursor: pointer;
}

.WA {
  cursor: pointer;
}

.AZ {
  cursor: pointer;
}

.IL {
  cursor: pointer;
}

.VA {
  cursor: pointer;
}
 */
